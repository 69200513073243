.footer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .banner-footer {
    display: flex;
    justify-content: center;
    & > img {
      height: 100px;
      border-radius: 10px;
      @media screen and (max-width: 700px) {
        height: 90px;
        border-radius: 30px;
      }
      @media screen and (max-width: 570px) {
        height: 80px;
        border-radius: 30px;
      }
      @media screen and (max-width: 500px) {
        height: 50px;
        border-radius: 30px;
      }
      @media screen and (max-width: 310px) {
        height: 40px;
        border-radius: 30px;
      }
      @media screen and (max-width: 240px) {
        height: 30px;
        border-radius: 30px;
      }
    }
  }
  span {
    padding-top: 8px;
    font-size: 8px;
    color: rgba(255, 255, 255, 0.233);
    margin-bottom: -8px !important;
  }
}

@primary-color: #e6e7e8;@secondary-color: #07436f;@primary-color-shade: darken(@primary-color, 47%);@background-color: #1e5c65;@success-color: #5bdcc6;@info-color: #2267e9;@default-color: #bbbbbb;@danger-color: #e21f1f;@text-color-light: rgb(253, 253, 253);@normal-color: @background-color;@text-color: #fff;@heading-color: @text-color;@input-placeholder-color: alpha(@text-color, 45%);@white: @background-color;@black: #fff;@body-background: @background-color;@component-background: lighten(@background-color, 5%);@popover-background: @component-background;@skeleton-color: #eee3;@menu-item-color: @primary-color;@layout-header-background: @component-background;@border-color-base: #eee5;@border-color-split: #eee2;@border-color-inverse: @white;@degree: 120deg;@grad-perc: -100%;@header-opacity: 0.8;@border-width: 3px;