@import "~styles/variables.less";

section.fade-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: fade(@background-color, 50%);
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;

  & > .loading {
    color: var(--primary-color);
    font-size: 60px;
  }

  &.dark {
    background-color: #f5f5f5;

    & > .loading {
      color: #6665;
    }
  }
}

@primary-color: #e6e7e8;@secondary-color: #07436f;@primary-color-shade: darken(@primary-color, 47%);@background-color: #1e5c65;@success-color: #5bdcc6;@info-color: #2267e9;@default-color: #bbbbbb;@danger-color: #e21f1f;@text-color-light: rgb(253, 253, 253);@normal-color: @background-color;@text-color: #fff;@heading-color: @text-color;@input-placeholder-color: alpha(@text-color, 45%);@white: @background-color;@black: #fff;@body-background: @background-color;@component-background: lighten(@background-color, 5%);@popover-background: @component-background;@skeleton-color: #eee3;@menu-item-color: @primary-color;@layout-header-background: @component-background;@border-color-base: #eee5;@border-color-split: #eee2;@border-color-inverse: @white;@degree: 120deg;@grad-perc: -100%;@header-opacity: 0.8;@border-width: 3px;