.sociais-media-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 232px;
  & > a {
    height: 32px;
    width: 32px;
    justify-content: center;
    align-items: center;
    display: flex;
    transition: all 0.3s ease-in-out;
    border-radius: 50%;
    &:hover {
      background-color: #1e5c65;
      opacity: 0.9;
      border-color: #1e5c65;
      box-shadow: 0 0 16px 4px fade(#1e5c65, 40%);
      transform: scale(1.2);
    }
  }
}

@primary-color: #e6e7e8;@secondary-color: #07436f;@primary-color-shade: darken(@primary-color, 47%);@background-color: #1e5c65;@success-color: #5bdcc6;@info-color: #2267e9;@default-color: #bbbbbb;@danger-color: #e21f1f;@text-color-light: rgb(253, 253, 253);@normal-color: @background-color;@text-color: #fff;@heading-color: @text-color;@input-placeholder-color: alpha(@text-color, 45%);@white: @background-color;@black: #fff;@body-background: @background-color;@component-background: lighten(@background-color, 5%);@popover-background: @component-background;@skeleton-color: #eee3;@menu-item-color: @primary-color;@layout-header-background: @component-background;@border-color-base: #eee5;@border-color-split: #eee2;@border-color-inverse: @white;@degree: 120deg;@grad-perc: -100%;@header-opacity: 0.8;@border-width: 3px;